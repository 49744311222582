<template>
  <div class="modal fade"
       id="confirmation"
       data-backdrop="static"
       tabindex="-1"
       role="dialog"
       aria-labelledby="confirmationLabel"
       aria-hidden="true"
       v-if="project.uuid">
    <div class="modal-dialog"
         role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1>Overzicht</h1>
          <button type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Sluiten">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="alert alert-warning"
               role="alert"
               v-if="disableConfirmOrder">
            Nog niet alle ruimten zijn geconfigureerd!
          </div>
          <div class="accordion"
               id="orderTotals">
            <div class="card"
                 v-for="room in project.rooms"
                 :key="`orderTotal_${room.uuid}`">
              <template v-if="room.order">
                <div class="card-header"
                     :id="`orderTotalHeader_${room.uuid}`">
                  <h2 class="mb-0">
                    <button class="btn btn-link collapsed"
                            type="button"
                            data-toggle="collapse"
                            :data-target="`#orderTotalCollapse_${room.uuid}`"
                            aria-expanded="false"
                            :aria-controls="`orderTotalCollapse_${room.uuid}`">
                      {{ room.name }}
                    </button>
                  </h2>
                </div>

                <div :id="`orderTotalCollapse_${room.uuid}`"
                     class="collapse"
                     :aria-labelledby="`#orderTotalHeader_${room.uuid}`"
                     data-parent="#orderTotals">
                  <div class="card-body">
                    <h2 class="my-2">{{ room.order.design_name }}</h2>
                    <ul class="list-group">
                      <div v-for="(includeCategory, index) in room.order.products.includedGroups"
                           :key="index">
                        <li v-for="(include, indx) in includeCategory.products"
                            :key="indx"
                            class="list-group-item">
                          <span>{{ includeCategory.name }}</span>
                        </li>
                      </div>
                      <li class="list-group-item d-flex justify-content-end last">
                        <div class="price d-flex align-items-center justify-content-between">
                          <small></small>
                          <span>{{ room.order.price | dutchCurrency }}</span>
                        </div>
                      </li>
                    </ul>

                    <!-- OPTIONAL PRODUCTS -->
                    <div v-if="room.order.products.optionalGroups.length">
                      <h2>Opties en uitbreidingen</h2>
                      <ul class="list-group">
                        <div v-for="(optionalCategory, index) in room.order.products
                            .optionalGroups"
                             :key="index">
                          <li v-for="(product, indx) in optionalCategory.products"
                              :key="indx"
                              class="list-group-item d-flex justify-content-between align-items-center">
                            <span class="info">{{ optionalCategory.name }}: {{ product.name }}</span>
                            <div class="price d-flex align-items-center justify-content-between">
                              <small></small>
                              <span>{{ product.price | dutchCurrency }}</span>
                            </div>
                          </li>
                        </div>
                      </ul>
                      <ul class="list-group">
                        <li class="list-group-item d-flex justify-content-end last">
                          <span>{{ room.order.surchargeOptional.price | dutchCurrency }}</span>
                        </li>
                      </ul>
                    </div>
                    <!-- CONFIGURABLE PROPERTIES -->
                    <div v-if="room.order.configurableProperties.length">
                      <h2>Keuzes</h2>
                      <ul class="list-group">
                        <div v-for="(property, index) in room.order.configurableProperties"
                             :key="index">
                          <li class="list-group-item d-flex align-items-center">
                            <span class="info">{{ property.name }}: {{ property.option.name }}</span>
                            <div class="price d-flex align-items-center justify-content-between">
                              <small></small>
                              <span>{{ property.option.price | dutchCurrency }}</span>
                            </div>
                          </li>
                        </div>
                        <li class="list-group-item d-flex justify-content-end last">
                          <span>{{
                            room.order.surchargeProperties.price | dutchCurrency
                          }}</span>
                        </li>
                      </ul>
                    </div>
                    <!-- TOTALPRICE -->
                    <ul class="list-group mt-5">
                      <small class="list-group-item d-flex justify-content-between align-items-center">
                        Totaal Producten:
                        <strong>{{ room.order.totalProductPrice | dutchCurrency }}</strong>
                      </small>
                      <small class="list-group-item d-flex justify-content-between align-items-center">
                        Totaal Installatie:
                        <strong>{{
                          room.order.totalInstallationPrice | dutchCurrency
                        }}</strong>
                      </small>
                      <li class="list-group-item d-flex justify-content-between align-items-center total">
                        Totaal meerprijs (incl. BTW):
                        <strong>{{ room.order.totalPrice | dutchCurrency }}</strong>
                      </li>
                    </ul>
                    <div class="text-center">
                      <button class="btn btn-link btn-sm"
                              data-dismiss="modal"
                              @click.prevent="selectRoom(room.uuid)"><u>Configuratie wijzigen</u></button>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <!-- TOTALPRICE -->
          <div>
            <ul class="list-group mt-5">
              <small class="list-group-item d-flex justify-content-between align-items-center">
                Totaal Producten:
                <strong>{{ getOrderTotal('totalProductPrice') | dutchCurrency }}</strong>
              </small>
              <small class="list-group-item d-flex justify-content-between align-items-center">
                Totaal Installatie:
                <strong>{{ getOrderTotal('totalInstallationPrice') | dutchCurrency }}</strong>
              </small>
              <li class="list-group-item d-flex justify-content-between align-items-center total">
                Totaal meerprijs (incl. BTW):
                <strong>{{ getOrderTotal('totalPrice') | dutchCurrency }}</strong>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal">
            Configuratie aanpassen
          </button>
          <button type="button"
                  class="btn btn-primary"
                  data-dismiss="modal"
                  @click.prevent="confirmOrder()"
                  :disabled="disableConfirmOrder">
            Deel uw reactie
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'modalConfirmation',
  computed: {
    ...mapGetters({
      isLoading: 'order/isLoading',
      order: 'order/getOrder',
      project: 'project/getProject',
    }),
    disableConfirmOrder () {
      return this.project?.rooms.filter(item => !item.disabled).find(item => item.order?.design_uuid) ? false : true
    },
  },
  methods: {
    confirmOrder () {
      this.$confirm(
        'Klik hieronder om uw keuze aan ons door te geven.',
        '',
        '',
        {
          confirmButtonText: 'Deel uw keuze',
          confirmButtonAriaLabel: 'Deel uw keuze',
          showCancelButton: true,
          cancelButtonText: 'Ga terug',
          cancelButtonAriaLabel: 'Ga terug',
        }
      )
        .then(() => {
          this.sendOrder();
        })
        .catch(() => true);
    },
    sendOrder () {
      const designs = this.project.rooms.filter(item => !item.disabled && item.order).map(item => ({
        design_uuid: item.order.design_uuid,
        optional_products: this.getOptionalProducts(item.order),
        configurable_properties: this.getConfigurableProperties(item.order),
      }))

      this.$store
        .dispatch('order/sendOrder', { designs })
        .then(() => {
          this.$router.push({ name: 'thanks' }, () => { });
        })
        .catch((err) => {
          if (err.response.data.result === 'invalid_request') {
            this.$toasted.error('Er is een onbekende fout opgetreden.');
          } else {
            this.$router.push({
              name: 'error',
              params: { errorText: err.response.data.result },
            });
          }
        });
    },
    getConfigurableProperties (order) {
      let arr = new Array();
      order.configurableProperties.forEach((property) => {
        let tempProperty = {};
        tempProperty.uuid = property.uuid;
        tempProperty.selected_option_uuid = property.option.uuid;
        arr.push(tempProperty);
      });
      return arr;
    },
    getOptionalProducts (order) {
      let arr = new Array();
      order.products.optionalGroups.forEach((group) => {
        group.products.forEach((product) => {
          let tempProduct = {};
          tempProduct.uuid = product.uuid;
          arr.push(tempProduct);
        });
      });
      return arr;
    },
    getOrderTotal (type) {
      return this.project.rooms.reduce((total, room) => {
        const value = room.order?.[type];
        if (!value) {
          return total;
        }
        return total + value;
      }, 0)
    },
    selectRoom (roomUuid) {
      if (this.$route.params.roomUUID === roomUuid) {
        return;
      }
      return this.$router.push({ name: "room", params: { roomUUID: roomUuid } });
    }
  },
};
</script>
