import Vue from "vue";
import Vuex from "vuex";

import customer from "@/store/modules/customer";
import project from "@/store/modules/project";
import order from "@/store/modules/order";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    customer: {
      namespaced: true,
      ...customer
    },
    project: {
      namespaced: true,
      ...project
    },
    order: {
      namespaced: true,
      ...order
    }
  }
});
