<template>
  <div></div>
</template>

<script>
export default {
  beforeMount() {
    this.$store
      .dispatch("customer/resetPassword", {
        reset_token: this.$route.query.token,
        customer_uuid: this.$route.query.uuid
      })
      .then(() => {
        this.$router.push({
          path: "/",
          query: { uuid: this.$route.query.uuid }
        });
      })
      .catch(err => {
        this.$router.push({
          name: "error",
          params: { errorText: err.response.data.result }
        });
      });
  }
};
</script>
