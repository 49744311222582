import axios from "axios";
import Vue from "vue";

import store from "@/store";

const showErrorMessage = messages => {
  Vue.prototype.$toasted.error(messages.join("<br>"), { duration: null });
};

const errorHandler = error => {
  // The request was made and the server responded with a status code
  // that falls out of the range of 2xx

  const isCancelled = error instanceof axios.Cancel;
  const status = error.response ? error.response.status : undefined;
  const noResponse = !error.response && error.request;

  if (isCancelled) {
    // Cancelling a request is presumably on purpose, so no need to show a default error message
    return Promise.reject(error);
  }

  switch (status) {
    case undefined:
      // No response received
      break;
    case 401:
      store.commit("auth/clearSession");
      showErrorMessage([
        'U bent uitgelogd. Log alstublieft&nbsp;<a href="/">opnieuw in</a>.'
      ]);
      return Promise.reject(error);
    case 500:
      showErrorMessage(["Er is een onbekende fout opgetreden."]);
      return Promise.reject(error);
    default:
      return Promise.reject(error);
  }

  if (noResponse) {
    // The request was made but no response was received (no network for example)
    showErrorMessage(["Er is een onbekende fout opgetreden."]);
    return Promise.reject(error);
  }

  // Something happened in setting up Axios that triggered an error
  showErrorMessage(["Er is een onbekende fout opgetreden."]);
  return Promise.reject(error);
};

export default function getAxiosInstance(payload = {}) {
  const axiosInstance = axios.create();

  const defaults = { enableErrorhandling: true };
  const options = { ...defaults, ...payload };

  if (options.enableErrorhandling) {
    axiosInstance.interceptors.response.use(
      response => response,
      err => errorHandler(err)
    );
  }

  return axiosInstance;
}
