var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        {
          staticClass: "content d-flex justify-content-end align-items-center"
        },
        [
          _c("small", [_vm._v("Totaal meerprijs (incl. BTW):")]),
          _c("strong", [
            _vm._v(_vm._s(_vm._f("dutchCurrency")(_vm.order.totalPrice)))
          ]),
          _vm.confirmProject
            ? _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.save }
                },
                [
                  _c("span", { staticClass: "d-none d-md-inline-block" }, [
                    _vm._v("Geconfigureerde")
                  ]),
                  _vm._v(" ruimten bevestigen ")
                ]
              )
            : _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.save }
                },
                [_vm._v(" Kies dit ontwerp ")]
              )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }