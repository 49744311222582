<template>
  <div id="designCarousel"
       class="carousel slide"
       data-ride="carousel">

    <div class="carousel-inner mb-3">
      <div class="carousel-item"
           :class="index === 0 ? 'active' : ''"
           v-for="(img, index) in designMedia"
           v-bind:key="index">
        <img :src="img"
             class="card-img"
             :alt="index" />
      </div>
    </div>
    <a  v-if="designMedia.length > 1" class="carousel-control-prev"
       href="#designCarousel"
       role="button"
       data-slide="prev">
      <span class="carousel-control-prev-icon"
            aria-hidden="true"></span>
      <span class="sr-only">Vorige</span>
    </a>
    <a  v-if="designMedia.length > 1" class="carousel-control-next"
       href="#designCarousel"
       role="button"
       data-slide="next">
      <span class="carousel-control-next-icon"
            aria-hidden="true"></span>
      <span class="sr-only">Volgende</span>
    </a>
    <ol v-if="designMedia.length > 1" class="carousel-indicators">
      <li v-for="(img, index) in designMedia"
          v-bind:key="index"
          data-target="#designCarousel"
          :data-slide-to="index"
          :class="index === 0 ? 'active' : ''">
        <img :src="img"
             class="card-thumb"
             :alt="index" />
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "designCarousel",
  props: {
    designMedia: {
      type: Array,
      required: true
    }
  }
};
</script>
