var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _vm.isLoading ? _c("loader") : _vm._e(),
      _vm.nouuid
        ? _c("div", { staticClass: "col col-lg-8 offset-lg-2" }, [
            _c("h1", [_vm._v("Klik opnieuw op de link in de email")]),
            _c("p", [
              _vm._v(
                " We kunnen uw account niet vinden, klik op de link in de ontvangen email om in te loggen/registreren. "
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }