<template>
  <div id="error"
       class="row">
    <div class="col col-lg-8 offset-lg-2">
      <h1>Pagina niet gevonden</h1>
      <p>De pagina waar u naar op zoek bent bestaat niet meer, is mogelijk verplaatst of is tijdelijk niet beschikbaar.</p>
      <p>Keer terug naar de <router-link to="/">homepagina &raquo;</router-link>.</p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>