var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row", attrs: { id: "error" } }, [
    _vm.error
      ? _c("div", { staticClass: "col col-lg-8 offset-lg-2" }, [
          _vm.error === "archived" ||
          _vm.error === "project_not_active" ||
          _vm.error === "customer_or_project_not_active" ||
          _vm.error === "customer_not_active" ||
          _vm.error === "project_not_found" ||
          _vm.error === "design_not_found" ||
          _vm.error === "customer_not_found" ||
          _vm.error === "customer_not_invited"
            ? _c("div", [
                _c("h1", [
                  _vm._v("Het project/account is niet gevonden of verlopen.")
                ]),
                _vm._m(0)
              ])
            : _vm._e(),
          _vm.error === "required_fields_missing"
            ? _c("div", [
                _c("h1", [_vm._v("Klik opnieuw op de link in de email")]),
                _c("p", [
                  _vm._v(
                    " We kunnen je account niet vinden, klik op de link in de ontvangen email om in te loggen/registreren. "
                  )
                ])
              ])
            : _vm._e(),
          _vm.error === "invalid_reset_token"
            ? _c("div", [
                _c("h1", [_vm._v("Er is iets fout gegaan.")]),
                _vm._m(1)
              ])
            : _vm._e(),
          _vm.error === "order_already_placed"
            ? _c("div", [
                _c("h1", [_vm._v("U heeft uw keuze al gedeeld.")]),
                _vm._m(2)
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" Je kunt op dit moment geen ruimten samenstellen."),
      _c("br"),
      _vm._v(" Klopt dit niet? Neem contact met ons op ("),
      _c("a", { attrs: { href: "mailto:cvasselt@intermat.nl" } }, [
        _vm._v("cvasselt@intermat.nl")
      ]),
      _vm._v(" of "),
      _c("a", { attrs: { href: "tel:0297-233533" } }, [_vm._v("0297-233533")]),
      _vm._v("). ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " Deze wachtwoord reset link is al gebruikt. Is dit niet het geval neem dan contact met ons op ("
      ),
      _c("a", { attrs: { href: "mailto:cvasselt@intermat.nl" } }, [
        _vm._v("cvasselt@intermat.nl")
      ]),
      _vm._v(" of "),
      _c("a", { attrs: { href: "tel:0297-233533" } }, [_vm._v("0297-233533")]),
      _vm._v("). ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" Klopt dit niet? Neem contact met ons op ("),
      _c("a", { attrs: { href: "mailto:cvasselt@intermat.nl" } }, [
        _vm._v("cvasselt@intermat.nl")
      ]),
      _vm._v(" of "),
      _c("a", { attrs: { href: "tel:0297-233533" } }, [_vm._v("0297-233533")]),
      _vm._v("). ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }