var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card mb-3 select-room" }, [
    _c(
      "div",
      {
        staticClass:
          "card-body d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap"
      },
      [
        _c(
          "h1",
          {
            staticClass:
              "card-title mb-2 mb-md-0 mr-md-3 text-center text-md-left flex-grow-1 flex-md-grow-0"
          },
          [_vm._v(_vm._s(_vm.room.name))]
        ),
        _vm.room.disabled
          ? _c(
              "button",
              {
                staticClass: "btn btn-success",
                attrs: { disabled: "disabled" }
              },
              [_vm._v(" ✓ Deze ruimte is besteld ")]
            )
          : !_vm.isRoomConfigured
          ? _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.selectRoom(_vm.room.uuid)
                  }
                }
              },
              [_vm._v(" Bekijk deze ruimte ")]
            )
          : _c(
              "button",
              {
                staticClass: "btn btn-outline-success",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.selectRoom(_vm.room.uuid)
                  }
                }
              },
              [
                _vm._v(" ✓ "),
                _c("span", { staticClass: "d-md-none d-lg-inline" }, [
                  _vm._v("Deze ruimte is ")
                ]),
                _vm._v("geconfigureerd ")
              ]
            )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }