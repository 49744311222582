var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _vm.isLoading ? _c("loader") : _vm._e(),
      _c("div", { staticClass: "col col-lg-8 offset-lg-2" }, [
        _c("h1", [_vm._v("Registreer")]),
        _c("p", [
          _vm._v(
            " Maak hier uw registratie compleet en voer een wachtwoord in. "
          )
        ]),
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("form", [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "password" } }, [
                  _vm._v("Kies een wachtwoord ")
                ]),
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center pw-container" },
                  [
                    _vm.passwordFieldType === "checkbox"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.password,
                              expression: "password"
                            }
                          ],
                          staticClass: "form-control password",
                          class: _vm.passwordError !== "" ? "error" : "",
                          attrs: { id: "password", type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.password)
                              ? _vm._i(_vm.password, null) > -1
                              : _vm.password
                          },
                          on: {
                            input: function($event) {
                              ;(_vm.passwordError = ""), _vm.checkPassword()
                            },
                            change: function($event) {
                              var $$a = _vm.password,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.password = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.password = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.password = $$c
                              }
                            }
                          }
                        })
                      : _vm.passwordFieldType === "radio"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.password,
                              expression: "password"
                            }
                          ],
                          staticClass: "form-control password",
                          class: _vm.passwordError !== "" ? "error" : "",
                          attrs: { id: "password", type: "radio" },
                          domProps: { checked: _vm._q(_vm.password, null) },
                          on: {
                            input: function($event) {
                              ;(_vm.passwordError = ""), _vm.checkPassword()
                            },
                            change: function($event) {
                              _vm.password = null
                            }
                          }
                        })
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.password,
                              expression: "password"
                            }
                          ],
                          staticClass: "form-control password",
                          class: _vm.passwordError !== "" ? "error" : "",
                          attrs: {
                            id: "password",
                            type: _vm.passwordFieldType
                          },
                          domProps: { value: _vm.password },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.password = $event.target.value
                              },
                              function($event) {
                                ;(_vm.passwordError = ""), _vm.checkPassword()
                              }
                            ]
                          }
                        }),
                    _c(
                      "a",
                      {
                        staticClass: "pw-visibility",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.switchVisibility()
                          }
                        }
                      },
                      [
                        _vm.passwordFieldType === "password"
                          ? _c("img", {
                              attrs: { src: require("../assets/img/eye.svg") }
                            })
                          : _vm._e(),
                        _vm.passwordFieldType === "text"
                          ? _c("img", {
                              attrs: {
                                src: require("../assets/img/eye-slash.svg")
                              }
                            })
                          : _vm._e()
                      ]
                    ),
                    _vm.lengthOK
                      ? _c("span", { staticClass: "ml-2 input-check" })
                      : _vm._e()
                  ]
                ),
                _c("span", { staticClass: "error-text" }, [
                  _vm._v(_vm._s(_vm.passwordError))
                ]),
                _c(
                  "small",
                  {
                    staticClass: "form-text text-muted",
                    attrs: { id: "passwordHelp" }
                  },
                  [_vm._v("Het wachtwoord moet minimaal 10 tekens bevatten")]
                )
              ]),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "submit" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.register()
                    }
                  }
                },
                [_vm._v(" Opslaan ")]
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }