var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card mb-3 select-design" }, [
    _c("div", { staticClass: "row no-gutters" }, [
      _c("div", { staticClass: "card-body d-flex flex-column d-lg-block" }, [
        _vm.design.cover_image
          ? _c("img", {
              staticClass:
                "card-img float-lg-left mr-lg-5 order-1 mb-3 mb-lg-0",
              attrs: { src: _vm.design.cover_image.url }
            })
          : _vm._e(),
        _c(
          "h1",
          {
            staticClass: "card-title text-center text-lg-left order-0 mb-lg-4"
          },
          [_vm._v(_vm._s(_vm.design.name))]
        ),
        _vm.design.description
          ? _c("p", { staticClass: "order-2" }, [
              _vm._v(" " + _vm._s(_vm.design.description) + " ")
            ])
          : _vm._e(),
        _c("div", { staticClass: "card-price order-3" }, [
          _vm.design.price === 0
            ? _c(
                "span",
                {
                  staticClass:
                    "included d-flex align-items-center justify-content-center justify-content-sm-start"
                },
                [_vm._v("Inbegrepen in uw nieuwe woning")]
              )
            : _vm._e(),
          _vm.design.price > 0
            ? _c(
                "span",
                {
                  staticClass:
                    "surcharge d-flex align-items-center justify-content-center justify-content-sm-start"
                },
                [
                  _c("small", [_vm._v("Meerprijs:")]),
                  _c("strong", [
                    _vm._v(_vm._s(_vm._f("dutchCurrency")(_vm.design.price)))
                  ])
                ]
              )
            : _vm._e(),
          _vm.design.price > 0
            ? _c(
                "span",
                {
                  staticClass:
                    "split-price d-flex align-items-center justify-content-center justify-content-sm-start"
                },
                [
                  _vm._v(
                    "Product: " +
                      _vm._s(
                        _vm._f("dutchCurrency")(_vm.design.product_price)
                      ) +
                      " / Installatie: " +
                      _vm._s(
                        _vm._f("dutchCurrency")(_vm.design.installation_price)
                      )
                  )
                ]
              )
            : _vm._e()
        ])
      ])
    ]),
    _c("div", { staticClass: "card-button" }, [
      !_vm.isDesignConfigured
        ? _c(
            "button",
            {
              staticClass: "btn btn-secondary",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.selectDesign(_vm.room.uuid, _vm.design.uuid)
                }
              }
            },
            [_vm._v(" Bekijk dit ontwerp ")]
          )
        : _c(
            "button",
            {
              staticClass: "btn btn-outline-success",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.selectDesign(_vm.room.uuid, _vm.design.uuid)
                }
              }
            },
            [
              _vm._v(" ✓ "),
              _c("span", { staticClass: "d-sm-none d-md-inline" }, [
                _vm._v("Dit ontwerp is ")
              ]),
              _vm._v("geconfigureerd ")
            ]
          )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }