/* eslint-disable import/no-cycle */
import getAxiosInstance from "@/lib/axios-instance";
/* eslint-enable import/no-cycle */

export default {
  customer: {
    init(payload = "", http = getAxiosInstance()) {
      return http
        .get(`/igm-projects/custom/customer/${payload}`)
        .then(response => {
          switch (response.data.result) {
            case "ok":
              return response.data;
          }
        });
    },
    register(uuid = "", password = "", http = getAxiosInstance()) {
      return http
        .post(`/igm-projects/custom/customer/${uuid}/register`, {
          password: password
        })
        .then(response => {
          switch (response.data.result) {
            case "ok":
              return response;
          }
        });
    },
    forgotPassword(payload = "", http = getAxiosInstance()) {
      return http.post(
        `/igm-projects/custom/customer/${payload}/forgot_password`
      );
    },
    resetPassword(payload = {}, http = getAxiosInstance()) {
      return http.post(
        `/igm-projects/custom/customer/${payload.customer_uuid}/reset_password`,
        { reset_token: payload.reset_token }
      );
    },
    login(payload = {}, http = getAxiosInstance()) {
      return http.post(`/igm-projects/custom/authentication/login`, payload);
    },
    logout(payload = {}, http = getAxiosInstance()) {
      return http.post(`/igm-projects/custom/authentication/logout`, payload);
    },
    getStatus(http = getAxiosInstance()) {
      return http.get("/igm-projects/custom/authentication/status");
    }
  },
  project: {
    getProject(payload = "", http = getAxiosInstance()) {
      return http.get(`/igm-projects/custom/project/${payload}`);
    },
    getRoom(payload = "",  http = getAxiosInstance()) {
      return http.get(`/igm-projects/custom/room/${payload}`);
    },
    getDesign(payload = "", http = getAxiosInstance()) {
      return http.get(`/igm-projects/custom/design/${payload}`);
    }
  },
  order: {
    sendOrder(payload = {}, http = getAxiosInstance()) {
      return http.post(
        `/igm-projects/custom/order/post`,
        payload
      );
    }
  }
};
