<template>
  <div id="rooms" class="row" v-if="loaded">
    <loader v-if="isLoading"></loader>
    <div class="col col-lg-12">
      <div class="row mb-5 flex align-items-center">
        <div class="col-sm-12 col-md-3 mb-5 mb-md-0">
          <img
            class="img-fluid"
            v-show="project.landing_page.logo"
            :src="project.landing_page.logo"
          />
        </div>
        <div class="col">
          <h1>{{ projectName }}</h1>
        </div>
      </div>
      <div
        class="landing-content"
        v-if="project.landing_page.contents"
        v-html="project.landing_page.contents"
      ></div>
      <div v-if="project.rooms" class="rooms">
        <div class="row">
          <div class="col">
            <select-room
              :room="room"
              v-for="room in project.rooms"
              :key="room.uuid"
            ></select-room>
          </div>
        </div>
      </div>
      <div
        class="landing-content"
        v-if="project.landing_page.miscellaneous"
        v-html="project.landing_page.miscellaneous"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import loader from '@/components/loader.vue';
import selectRoom from '@/components/select-room.vue';

export default {
  name: 'rooms',
  components: {
    loader,
    selectRoom,
  },
  data() {
    return {
      projectName: sessionStorage.getItem('project_name'),
      loaded: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'project/isLoading',
      project: 'project/getProject',
    }),
  },
  beforeMount() {
    if (!this.project.uuid) {
      this.$store
        .dispatch('project/getProject', sessionStorage.getItem('project_UUID'))
        .then(() => {
          this.loaded = true;
        });
    } else {
      this.loaded = true;
    }
  },
  methods: {
    selectRoom(uuid) {
      this.$router.push({ name: 'room', params: { roomUUID: uuid } });
    },
  },
};
</script>
