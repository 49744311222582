<template>
  <footer>
    <div class="container">
      <div class="content d-flex justify-content-end align-items-center">
        <small>Totaal meerprijs (incl. BTW):</small>
        <strong>{{order.totalPrice | dutchCurrency}}</strong>
        <button
          v-if="confirmProject"
          type="button"
          class="btn btn-primary"
          @click="save"
        >
          <span class="d-none d-md-inline-block">Geconfigureerde</span> ruimten bevestigen
        </button>
        <button
          v-else
          type="button"
          class="btn btn-primary"
          @click="save"
        >
          Kies dit ontwerp
        </button>
      </div>
    </div>
  </footer>
</template>

<script>
import $ from 'jquery';
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: "priceFooter",
  computed: {
    ...mapGetters({
      order: "order/getOrder",
      project: 'project/getProject',
      room: 'project/getRoom'
    }),
    confirmProject() {
      const currentProjectRoom = this.project.rooms.find(item => item.uuid === this.room.uuid);
      const unconfiguredRoomsCount = this.project.rooms.filter(item => !item.order?.design_uuid && !item.disabled).length;
      return unconfiguredRoomsCount === 0 || (unconfiguredRoomsCount < 2 && !currentProjectRoom?.order);
    },
  },
  methods: {
    ...mapMutations({
      setProjectRoomDesignOrder: 'project/setProjectRoomDesignOrder',
    }),
    save() {
      this.setProjectRoomDesignOrder(JSON.parse(JSON.stringify(this.order)));
      if (this.project.rooms.filter(item => !item.disabled).every(item => item.order)) {
        $('#confirmation').modal('show');
      } else {
        this.$router.push({name: 'rooms'})
      }
    }
  }
};
</script>
