import api from '@/api';
import Vue from 'vue';

const getDefaultState = () => {
  return {
    isLoading: false,
    surchargeOptional: { installation_price: 0, product_price: 0, price: 0 },
    surchargeProperties: { installation_price: 0, product_price: 0, price: 0 },
    optionalGroups: new Array(),
    configurableProperties: new Array()
  };
};

export default {
  state: getDefaultState(),
  mutations: {
    setLoadingState(state, payload) {
      state.isLoading = payload;
    },
    setConfiguration(state, payload) {
      state.surchargeOptional = payload.surchargeOptional;
      state.surchargeProperties = payload.surchargeProperties;
      state.optionalGroups = payload.products.optionalGroups;
      state.configurableProperties = payload.configurableProperties;
    },
    updateOptional(state, payload) {
      const index = state.optionalGroups.findIndex(
        e => e.name === payload.name
      );
      index === -1
        ? state.optionalGroups.push(payload)
        : Vue.set(state.optionalGroups, index, payload);
    },
    updateProperty(state, payload) {
      const index = state.configurableProperties.findIndex(
        e => e.name === payload.name
      );
      index === -1
        ? state.configurableProperties.push(payload)
        : Vue.set(state.configurableProperties, index, payload);
    },
    updatePriceOptional(state, payload) {
      if (payload.action) {
        state.surchargeOptional.price += payload.price;
        state.surchargeOptional.product_price += payload.product_price;
        state.surchargeOptional.installation_price +=
          payload.installation_price;
      } else {
        state.surchargeOptional.price -= payload.price;
        state.surchargeOptional.product_price -= payload.product_price;
        state.surchargeOptional.installation_price -=
          payload.installation_price;
      }
    },
    updatePriceProperties(state) {
      state.surchargeProperties.price = 0;
      state.surchargeProperties.product_price = 0;
      state.surchargeProperties.installation_price = 0;
      state.configurableProperties.forEach(property => {
        state.surchargeProperties.price += property.option.price;
        state.surchargeProperties.product_price +=
          property.option.product_price;
        state.surchargeProperties.installation_price +=
          property.option.installation_price;
      });
    },
    resetOrder(state) {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    updateOptional({ commit }, optional) {
      let group = {
        name: optional.group.name,
        products: optional.group.products.filter(product => product.selected)
      };
      commit('updateOptional', group);
      commit('updatePriceOptional', {
        action: optional.item.selected,
        price: optional.item.price,
        product_price: optional.item.product_price,
        installation_price: optional.item.installation_price
      });
    },
    updateProperties({ commit }, payload) {
      let property = {
        name: payload.designProperties.name,
        uuid: payload.designProperties.uuid,
        option: payload.option
      };
      commit('updateProperty', property);
      commit('updatePriceProperties');
    },
    sendOrder({ commit }, payload) {
      commit('setLoadingState', true);
      return api.order.sendOrder(payload).finally(() => {
        commit('setLoadingState', false);
      });
    },
    resetOrder({ commit }) {
      commit('resetOrder');
    },
  },
  getters: {
    isLoading: state => state.isLoading,
    getProject: state => state.project,
    getRoom: state => state.room,
    getDesign: state => state.design,
    getOrder: (state, getters, rootState) => {
      return {
        design_name: rootState.project.design.name,
        design_uuid: rootState.project.design.uuid,
        price: rootState.project.design.price,
        product_price: rootState.project.design.product_price,
        installation_price: rootState.project.design.installation_price,
        surchargeOptional: state.surchargeOptional,
        surchargeProperties: state.surchargeProperties,
        totalInstallationPrice:
          rootState.project.design.installation_price +
          state.surchargeOptional.installation_price +
          state.surchargeProperties.installation_price,
        totalProductPrice:
          rootState.project.design.product_price +
          state.surchargeOptional.product_price +
          state.surchargeProperties.product_price,
        totalPrice:
          rootState.project.design.price +
          state.surchargeOptional.price +
          state.surchargeProperties.price,
        configurableProperties: state.configurableProperties,
        products: {
          includedGroups: rootState.project.design.products.includedGroups,
          optionalGroups: state.optionalGroups
        }
      };
    }
  }
};
