var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "mb-3" }, [
        _c("h3", [_vm._v(_vm._s(_vm.designProperties.name))]),
        _c(
          "div",
          {
            staticClass:
              "properties d-flex flex-wrap align-items-start justify-content-start"
          },
          _vm._l(_vm.designProperties.options, function(option, index) {
            return _c(
              "div",
              { key: index, staticClass: "form-check form-check-inline" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedOption,
                      expression: "selectedOption"
                    }
                  ],
                  staticClass: "form-check-input",
                  attrs: { type: "radio", name: option.name, id: option.uuid },
                  domProps: {
                    value: option.uuid,
                    checked: _vm._q(_vm.selectedOption, option.uuid)
                  },
                  on: {
                    change: [
                      function($event) {
                        _vm.selectedOption = option.uuid
                      },
                      function($event) {
                        return _vm.selectOption(option, _vm.designProperties)
                      }
                    ]
                  }
                }),
                _c(
                  "label",
                  {
                    staticClass: "form-check-label",
                    class: _vm.selectedOption === option.uuid ? "selected" : "",
                    attrs: { for: option.uuid }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "img-container",
                        style: {
                          backgroundImage: "url(" + option.images[0] + ")"
                        }
                      },
                      [
                        _c("div", {
                          staticClass: "img-overlay d-none d-sm-block",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.showImageModal(option.images)
                            }
                          }
                        })
                      ]
                    ),
                    _c("div", {
                      staticClass: "img-eye d-block d-sm-none",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.showImageModal(option.images)
                        }
                      }
                    }),
                    _c("span", [_vm._v(_vm._s(option.name))]),
                    _c("small", [
                      _vm._v(
                        "(+ " +
                          _vm._s(_vm._f("dutchCurrency")(option.price)) +
                          ")"
                      )
                    ])
                  ]
                )
              ]
            )
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }