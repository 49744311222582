<template>
  <header>
    <div class="container d-flex align-items-center mb-0">
      <div class="align-self-start">
        <img
          alt="Intermat Projects"
          src="../assets/img/intermatlogo_200.jpg"
        style="width:50% !important; margin: 5%;"  
	class="w-100 pr-2 pr-md-3 pr-lg-0"
        />
      </div>
      <div>
        <h2 class="d-none d-sm-none d-lg-block text-nowrap">
          <template v-if="!enableConfirmationModal">
            Stel uw ruimte samen
          </template>
          <button v-else class="btn btn-primary" data-toggle="modal" data-target="#confirmation">
            <template v-if="enableConfirmationSubmit">
              &check; Geconfigureerde ruimten bevestigen
            </template>
            <template v-else>
              Geconfigureerde ruimten bekijken
            </template>
          </button>
        </h2>
      </div>
      <div class="flex-grow-1 ml-auto mr-0 d-flex justify-content-end">
        <transition name="fade">
          <div class="project-name py-2"
               v-if="projectName">
            <p>Project:</p>
            <h4>{{ projectName }}</h4>
          </div>
        </transition>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "heading",
  data() {
    return {
      projectName: null
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "customer/isAuthenticated",
      project: 'project/getProject'
    }),
    enableConfirmationModal() {
      return this.project?.rooms ? this.project.rooms.filter(item => !item.disabled).find(item => item.order?.design_uuid) : false
    },
    enableConfirmationSubmit() {
      return this.project?.rooms ? this.project.rooms.filter(item => !item.disabled).every(item => item.order?.design_uuid) : false;
    }
  },
  mounted() {
    setTimeout(() => {
      this.projectName = sessionStorage.getItem("project_name");
    }, 500);
  }
};
</script>
