<template>
  <div class="card mb-3 select-design">
    <div class="row no-gutters">
      <div class="card-body d-flex flex-column d-lg-block">
        <img v-if="design.cover_image" :src="design.cover_image.url" class="card-img float-lg-left mr-lg-5 order-1 mb-3 mb-lg-0" />
        <h1 class="card-title text-center text-lg-left order-0 mb-lg-4">{{ design.name }}</h1>
        <p v-if="design.description" class="order-2">
          {{ design.description }}
        </p>
        <div class="card-price order-3">
          <span class="included d-flex align-items-center justify-content-center justify-content-sm-start"
                v-if="design.price === 0">Inbegrepen in uw nieuwe woning</span>
          <span class="surcharge d-flex align-items-center justify-content-center justify-content-sm-start"
                v-if="design.price > 0">
            <small>Meerprijs:</small>
            <strong>{{ design.price | dutchCurrency }}</strong>
          </span>
          <span v-if="design.price > 0"
                class="split-price d-flex align-items-center justify-content-center justify-content-sm-start">Product: {{design.product_price | dutchCurrency}} / Installatie: {{design.installation_price | dutchCurrency}}</span>
        </div>
      </div>
    </div>

    <div class="card-button">
      <button
        v-if="!isDesignConfigured"
        type="button"
        class="btn btn-secondary"
        @click.prevent="selectDesign(room.uuid, design.uuid)"
      >
        Bekijk dit ontwerp
      </button>
      <button
        v-else
        class="btn btn-outline-success"
        @click.prevent="selectDesign(room.uuid, design.uuid)"
      >
        &check; <span class="d-sm-none d-md-inline">Dit ontwerp is </span>geconfigureerd
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "selectDesign",
  props: {
    room: {
      type: Object,
      required: true
    },
    design: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      project: 'project/getProject',
    }),
    isDesignConfigured() {
      return this.project.rooms.some(item => item?.order?.design_uuid === this.design.uuid);
    }
  },
  methods: {
    selectDesign (roomUuid, designUuid) {
      this.$router.push({ name: "design", params: { roomUUID: roomUuid, designUUID: designUuid } });
    },
  }
};
</script>
