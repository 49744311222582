var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "room" } },
    [
      _vm.isLoading ? _c("loader") : _vm._e(),
      _c("div", { staticClass: "row mb-5" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("router-link", { attrs: { to: { name: "rooms" } } }, [
              _vm._v("« Terug naar ruimten")
            ])
          ],
          1
        )
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.loaded
          ? _c("div", [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("h1", [_vm._v("Selecteer ontwerp")]),
                  _c("p", [
                    _vm._v(
                      " Het leuke werk kan beginnen! Onderstaand vindt u de ontwerpen die voor de gekozen ruimte voor uw woning zijn uitgewerkt. Na selecteren van het ontwerp, wordt getoond uit welke artikelen het ontwerp is opgebouwd. Per ontwerp kunt u keuzes maken voor alternatieve artikelen om het totaalplaatje nog beter aan te laten sluiten op uw wensen. "
                    )
                  ])
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  _vm._l(_vm.room.designs, function(design) {
                    return _c("select-design", {
                      key: design.uuid,
                      attrs: { room: _vm.room, design: design }
                    })
                  }),
                  1
                )
              ])
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }