<template>
  <div></div>
</template>

<script>
export default {
  name: "custom",
  beforeMount() {
    window.location.href = `/info/${this.$route.params.pathMatch}`
  }
};
</script>
