var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "modalImage",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "modal-image",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _c(
            "div",
            {
              staticClass: "carousel slide",
              attrs: { id: "mediaCarousel", "data-ride": "carousel" }
            },
            [
              _c(
                "div",
                { staticClass: "carousel-inner mb-3" },
                _vm._l(_vm.media, function(img, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "carousel-item",
                      class: index === 0 ? "active" : ""
                    },
                    [
                      _c("img", {
                        staticClass: "card-img",
                        attrs: { src: img, alt: index }
                      })
                    ]
                  )
                }),
                0
              ),
              _vm.media.length > 1
                ? _c(
                    "a",
                    {
                      staticClass: "carousel-control-prev",
                      attrs: {
                        href: "#mediaCarousel",
                        role: "button",
                        "data-slide": "prev"
                      }
                    },
                    [
                      _c("span", {
                        staticClass: "carousel-control-prev-icon",
                        attrs: { "aria-hidden": "true" }
                      }),
                      _c("span", { staticClass: "sr-only" }, [_vm._v("Vorige")])
                    ]
                  )
                : _vm._e(),
              _vm.media.length > 1
                ? _c(
                    "a",
                    {
                      staticClass: "carousel-control-next",
                      attrs: {
                        href: "#mediaCarousel",
                        role: "button",
                        "data-slide": "next"
                      }
                    },
                    [
                      _c("span", {
                        staticClass: "carousel-control-next-icon",
                        attrs: { "aria-hidden": "true" }
                      }),
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v("Volgende")
                      ])
                    ]
                  )
                : _vm._e(),
              _vm.media.length > 1
                ? _c(
                    "ol",
                    { staticClass: "carousel-indicators" },
                    _vm._l(_vm.media, function(img, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          class: index === 0 ? "active" : "",
                          attrs: {
                            "data-target": "#mediaCarousel",
                            "data-slide-to": index
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "card-thumb",
                            attrs: { src: img, alt: index }
                          })
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e()
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Sluiten"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }