<template>
  <div class="list-group optional mt-5">
    <div class="mb-2"
         v-for="(group, index) in designOptional"
         :key="index">
      <h3>{{ group.name }}</h3>
      <div class="list-group-item list-group-item-action d-flex align-items-center flex-wrap"
           :class="item.selected ? 'selected' : ''"
           v-for="(item, itemIndex) in group.products"
           :key="itemIndex">
        <a @click.prevent="selectItem(item, group)">
          <span class="check"
                :class="item.selected ? 'active' : ''"></span>
        </a>
        <a class='img-container'
           :style="{ backgroundImage: 'url(' + item.images[0] + ')' }"
           @click.prevent="showImageModal(item.images)">
          <div class="img-overlay"></div>
          <img :src="item.images[0]"
               class="card-img"
               :alt="item.name"
               style="visibility:hidden" />
        </a>
        <a class="description flex-grow-1"
           @click.prevent="selectItem(item, group)">
          <span>{{ item.name }}</span>
        </a>
        <span class="price ml-auto">{{ item.price | dutchCurrency }}</span>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "designOptional",
  props: {
    designOptional: {
      type: Array,
      required: true
    }
  },
  methods: {
    showImageModal (media) {
      this.$parent.$emit('showImageModal', media)
    },
    selectItem (item, group) {
      item.selected = item.selected ? false : true;
      this.$store.dispatch("order/updateOptional", { item, group });
    }
  }
};
</script>
