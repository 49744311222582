<template>
  <div class="row">
    <div class="col">
      <div class="mb-3">
        <h3>{{ designProperties.name }}</h3>
        <div
          class="properties d-flex flex-wrap align-items-start justify-content-start"
        >
          <div
            v-for="(option, index) in designProperties.options"
            :key="index"
            class="form-check form-check-inline"
          >
            <input
              class="form-check-input"
              type="radio"
              :name="option.name"
              :id="option.uuid"
              v-model="selectedOption"
              :value="option.uuid"
              @change="selectOption(option, designProperties)"
            />
            <label
              class="form-check-label"
              :class="selectedOption === option.uuid ? 'selected' : ''"
              :for="option.uuid"
            >
              <div
                class="img-container"
                :style="{ backgroundImage: 'url(' + option.images[0] + ')' }"
              >
                <div
                  class="img-overlay d-none d-sm-block"
                  @click.prevent="showImageModal(option.images)"
                ></div>
              </div>
              <div
                class="img-eye d-block d-sm-none"
                @click.prevent="showImageModal(option.images)"
              ></div>
              <span>{{ option.name }}</span>
              <small>(+ {{ option.price | dutchCurrency }})</small>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'designProperties',
  props: {
    designProperties: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedOption: '',
    };
  },
  mounted() {
    //set first selected
    if (this.designProperties.options.length) {
      const configuredOption = this.designProperties.options.find(item => item.selected);
      if (configuredOption) {
        this.selectedOption = configuredOption.uuid;
      } else {
        this.selectedOption = this.designProperties.options[0].uuid;
        let option = this.designProperties.options[0];
        let designProperties = this.designProperties;
        this.$store.dispatch('order/updateProperties', {
          option,
          designProperties,
        });
      }
    }
  },
  methods: {
    selectOption(option, designProperties) {
      this.$store.dispatch('order/updateProperties', {
        option,
        designProperties,
      });
    },
    showImageModal(media) {
      this.$parent.$emit('showImageModal', media);
    },
  },
};
</script>
