import Vue from 'vue';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/modal';

import VueSimpleAlert from 'vue-simple-alert';

import '@/scss/base.scss';
import '@/config';

import App from '@/App.vue';
import '@/config';
import router from '@/router';
import store from '@/store';

Vue.config.productionTip = false;
Vue.use(VueSimpleAlert);

store.dispatch('customer/loadSessionFromStorage').finally(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
});
