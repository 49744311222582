<template>
  <div>
    <h3 class="my-4">Wat is er in dit ontwerp opgenomen?</h3>
    <div class="accordion"
         id="includedGroups">
      <div class="card"
           v-for="(group, index) in designIncluded"
           :key="index">
        <div class="card-header"
             id="headingOne">
          <h2 class="mb-0">
            <button class="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    :data-target="'#' + group.id"
                    aria-expanded="false"
                    :aria-controls="group.name">
              {{ group.name }}
            </button>
          </h2>
        </div>

        <div :id="group.id"
             class="collapse"
             aria-labelledby="headingOne">
          <div class="card-body">
            <div class="item-detail d-flex align-items-center"
                 v-for="(item, index) in group.products"
                 :key="index">
              <a class='img-container'
                 :style="{ backgroundImage: 'url(' + item.images[0] + ')' }"
                 @click.prevent="showImageModal(item.images)">
                <div class="img-overlay"></div>

              </a>
              <p>
                {{ item.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "designIncluded",
  props: {
    designIncluded: {
      type: Array,
      required: true
    }
  },
  methods: {
    showImageModal (media) {
      this.$parent.$emit('showImageModal', media)
    },
  }
};
</script>
