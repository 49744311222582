var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "row", attrs: { id: "rooms" } },
        [
          _vm.isLoading ? _c("loader") : _vm._e(),
          _c("div", { staticClass: "col col-lg-12" }, [
            _c("div", { staticClass: "row mb-5 flex align-items-center" }, [
              _c("div", { staticClass: "col-sm-12 col-md-3 mb-5 mb-md-0" }, [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.project.landing_page.logo,
                      expression: "project.landing_page.logo"
                    }
                  ],
                  staticClass: "img-fluid",
                  attrs: { src: _vm.project.landing_page.logo }
                })
              ]),
              _c("div", { staticClass: "col" }, [
                _c("h1", [_vm._v(_vm._s(_vm.projectName))])
              ])
            ]),
            _vm.project.landing_page.contents
              ? _c("div", {
                  staticClass: "landing-content",
                  domProps: {
                    innerHTML: _vm._s(_vm.project.landing_page.contents)
                  }
                })
              : _vm._e(),
            _vm.project.rooms
              ? _c("div", { staticClass: "rooms" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col" },
                      _vm._l(_vm.project.rooms, function(room) {
                        return _c("select-room", {
                          key: room.uuid,
                          attrs: { room: room }
                        })
                      }),
                      1
                    )
                  ])
                ])
              : _vm._e(),
            _vm.project.landing_page.miscellaneous
              ? _c("div", {
                  staticClass: "landing-content",
                  domProps: {
                    innerHTML: _vm._s(_vm.project.landing_page.miscellaneous)
                  }
                })
              : _vm._e()
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }