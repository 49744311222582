var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row", attrs: { id: "thanks" } },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _c("div", { staticClass: "col col-lg-8 offset-lg-2" }, [
          _c("h1", [_vm._v("Bedankt!")]),
          _c("p", [
            _vm._v(
              "Bedankt voor uw keuze! Uw keuze is alleen ter informatie voor u en voor ons. Dit is geen definitieve keuze en dient besproken te worden in de showroom. Binnen enkele ogenblikken ontvangt u een e-mail met bevestiging van de gekozen items."
            )
          ]),
          _c("p", [_vm._v(" U bent nu uitgelogd. ")])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }