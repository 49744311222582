import Vue from "vue";
import Toasted from "vue-toasted";

// Config default toast
Vue.use(Toasted, {
  iconPack: "fontawesome",
  closeOnSwipe: false,
  position: "top-right",
  duration: 3200,
  containerClass: "toasted-container-bootstrap", // applies our custom styles.
  // Add close button
  action: [
    {
      text: "×",
      onClick: (e, toastObject) => {
        toastObject.goAway(0);
      }
    }
  ]
});
