import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import init from "@/views/init.vue";
import registration from "@/views/registration.vue";
import authentication from "@/views/authentication.vue";
import rooms from "@/views/rooms.vue";
import room from "@/views/room.vue";
import design from "@/views/design.vue";
import error from "@/views/error.vue";
import reset from "@/views/reset.vue";
import thanks from "@/views/thanks.vue";
import custom from "@/views/custom.vue";
import notFound from "@/views/not-found.vue";

Vue.use(VueRouter);

const initGuard = (to, from, next) => {
  //check if we have a customer
  if (!sessionStorage.getItem("customer_UUID")) {
    next("/");
  } else {
    next();
  }
  //if we already logged in go to listing page
  if (JSON.parse(sessionStorage.getItem("isAuthenticated"))) {
    store.dispatch("customer/getStatus").then(status => {
      if (status === "authenticated") {
        next("/ruimten");
      }
    });
  }
};

const authenticatedGuard = (to, from, next) => {
  if (store.getters["customer/isAuthenticated"]) {
    next();
  } else if (!store.getters["customer/isAuthenticated"]) {
    store.dispatch("customer/logout");
    next({
      path: "/",
      query: {
        uuid: sessionStorage.getItem("UUID") || ""
      }
    });
  }
};

const logout = (to, from, next) => {
  store.commit('project/resetProject');
  store.dispatch("customer/logout");
  next();
}

const routes = [
  {
    path: "/",
    name: "init",
    component: init
  },
  {
    path: "/registratie",
    name: "registration",
    component: registration,
    beforeEnter: initGuard
  },
  {
    path: "/login",
    name: "authentication",
    component: authentication,
    beforeEnter: initGuard
  },
  {
    path: "/ruimten",
    name: "rooms",
    component: rooms,
    beforeEnter: authenticatedGuard
  },
  {
    path: "/ruimte/:roomUUID",
    name: "room",
    component: room,
    beforeEnter: authenticatedGuard
  },
  {
    path: "/ruimte/:roomUUID/ontwerp/:designUUID",
    name: "design",
    component: design,
    beforeEnter: authenticatedGuard
  },
  {
    path: "/bedankt",
    name: "thanks",
    component: thanks,
    beforeEnter: logout
  },
  {
    path: "/reset",
    name: "reset",
    component: reset
  },
  {
    path: "/error",
    name: "error",
    component: error
  },
  {
    path: "/project-*",
    name: "custom",
    component: custom
  },
  {
    path: "*",
    component: notFound
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
