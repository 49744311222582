var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h3", { staticClass: "my-4" }, [
      _vm._v("Wat is er in dit ontwerp opgenomen?")
    ]),
    _c(
      "div",
      { staticClass: "accordion", attrs: { id: "includedGroups" } },
      _vm._l(_vm.designIncluded, function(group, index) {
        return _c("div", { key: index, staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-header", attrs: { id: "headingOne" } },
            [
              _c("h2", { staticClass: "mb-0" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-link collapsed",
                    attrs: {
                      type: "button",
                      "data-toggle": "collapse",
                      "data-target": "#" + group.id,
                      "aria-expanded": "false",
                      "aria-controls": group.name
                    }
                  },
                  [_vm._v(" " + _vm._s(group.name) + " ")]
                )
              ])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "collapse",
              attrs: { id: group.id, "aria-labelledby": "headingOne" }
            },
            [
              _c(
                "div",
                { staticClass: "card-body" },
                _vm._l(group.products, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "item-detail d-flex align-items-center"
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "img-container",
                          style: {
                            backgroundImage: "url(" + item.images[0] + ")"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.showImageModal(item.images)
                            }
                          }
                        },
                        [_c("div", { staticClass: "img-overlay" })]
                      ),
                      _c("p", [_vm._v(" " + _vm._s(item.name) + " ")])
                    ]
                  )
                }),
                0
              )
            ]
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }