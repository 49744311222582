<template>
  <div id="app" class="container-fluid">
    <heading></heading>
    <main class="container mt-5">
      <router-view />
    </main>
    <modal-confirmation></modal-confirmation>
  </div>
</template>

<script>
import heading from "@/components/header.vue";
import modalConfirmation from '@/components/modal-confirmation.vue';
export default {
  name: "app",
  components: {
    heading,
    modalConfirmation
  },
};
</script>
