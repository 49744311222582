var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid", attrs: { id: "app" } },
    [
      _c("heading"),
      _c("main", { staticClass: "container mt-5" }, [_c("router-view")], 1),
      _c("modal-confirmation")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }