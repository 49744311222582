import api from "@/api";

export default {
  state: {
    isLoading: false,
    customer: {},
    isAuthenticated: false
  },
  mutations: {
    setLoadingState(state, payload) {
      state.isLoading = payload;
    },
    setCustomer(state, payload) {
      state.customer = payload;
    },
    setSession(state, payload) {
      state.isAuthenticated = payload;
      sessionStorage.setItem("isAuthenticated", payload);
    },
    clearSession(state) {
      state.isAuthenticated = false;
      state.customer = {};
      sessionStorage.removeItem("isAuthenticated");
      sessionStorage.removeItem("customer_UUID");
      sessionStorage.removeItem("customer_name");
      sessionStorage.removeItem("project_UUID");
      sessionStorage.removeItem("project_name");
    }
  },
  actions: {
    init({ commit }, uuid) {
      commit("setLoadingState", true);
      return api.customer
        .init(uuid)
        .then(response => {
          commit("setCustomer", response.customer);
          sessionStorage.setItem("customer_UUID", response.customer.uuid);
          sessionStorage.setItem("customer_name", response.customer.name);
          sessionStorage.setItem(
            "project_UUID",
            response.customer.project.uuid
          );
          sessionStorage.setItem(
            "project_name",
            response.customer.project.name
          );

          return response;
        })
        .finally(() => {
          commit("setLoadingState", false);
        });
    },
    register({ commit }, payload) {
      commit("setLoadingState", true);
      return api.customer
        .register(payload.uuid, payload.password)
        .then(response => {
          return response.data.result;
        })
        .finally(() => {
          commit("setLoadingState", false);
        });
    },
    forgotPassword({ commit }, payload) {
      commit("setLoadingState", true);
      return api.customer.forgotPassword(payload).finally(() => {
        commit("setLoadingState", false);
      });
    },
    resetPassword({ commit }, payload) {
      commit("setLoadingState", true);
      return api.customer.resetPassword(payload).finally(() => {
        commit("setLoadingState", false);
      });
    },
    login({ commit }, payload) {
      commit("setLoadingState", true);
      return api.customer
        .login(payload)
        .then(response => {
          commit(
            "setSession",
            response.data.status === "authenticated" ? true : false
          );
          return response;
        })
        .finally(() => {
          commit("setLoadingState", false);
        });
    },
    logout({ commit }) {
      return api.customer.logout().then(() => {
        commit("clearSession");
      });
    },
    loadSessionFromStorage({ commit, dispatch }) {
      let session = null;
      try {
        // Check if the session in local storage contains valid JSON
        session = JSON.parse(sessionStorage.getItem("isAuthenticated"));
      } catch {
        session = null;
      }

      if (!session) {
        return Promise.resolve();
      }

      commit("setSession", session);
      // Check if the session is still valid
      return dispatch("getStatus");
    },
    getStatus({ commit }) {
      return api.customer.getStatus().then(response => {
        let authenticated =
          response.data.status === "authenticated" ? true : false;
        commit("setSession", authenticated);
        return response.data.status;
      });
    }
  },
  getters: {
    isLoading: state => state.isLoading,
    isAuthenticated: state => state.isAuthenticated,
    getCustomer: state => state.customer
  }
};
