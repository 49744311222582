<template>
  <div id="thanks" class="row">
    <transition name="fade">
      <div class="col col-lg-8 offset-lg-2">
        <h1>Bedankt!</h1>
        <p>Bedankt voor uw keuze! Uw keuze is alleen ter informatie voor u en voor ons. Dit is geen definitieve keuze en dient besproken te worden in de showroom. Binnen enkele ogenblikken ontvangt u een e-mail met bevestiging van de gekozen items.</p>
        <p>
          U bent nu uitgelogd.
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "thanks"
};
</script>
