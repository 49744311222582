<template>
  <div class="card mb-3 select-room">
    <div class="card-body d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
      <h1 class="card-title mb-2 mb-md-0 mr-md-3 text-center text-md-left flex-grow-1 flex-md-grow-0">{{ room.name }}</h1>
      <button
        v-if="room.disabled"
        class="btn btn-success"
        disabled="disabled"
      >
        &check; Deze ruimte is besteld
      </button>
      <button
        v-else-if="!isRoomConfigured"
        type="button"
        class="btn btn-secondary"
        @click.prevent="selectRoom(room.uuid)"
      >
        Bekijk deze ruimte
      </button>
      <button
        v-else
        class="btn btn-outline-success"
        @click.prevent="selectRoom(room.uuid)"
      >
        &check; <span class="d-md-none d-lg-inline">Deze ruimte is </span>geconfigureerd
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "selectRoom",
  props: {
    room: {
      type: Object,
      required: true
    }
  },
  computed: {
    isRoomConfigured() {
      return this.room.order?.design_uuid;
    }
  },
  methods: {
    selectRoom (roomUuid) {
      this.$router.push({ name: "room", params: { roomUUID: roomUuid } });
    }
  }
};
</script>
