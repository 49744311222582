var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-group optional mt-5" },
    _vm._l(_vm.designOptional, function(group, index) {
      return _c(
        "div",
        { key: index, staticClass: "mb-2" },
        [
          _c("h3", [_vm._v(_vm._s(group.name))]),
          _vm._l(group.products, function(item, itemIndex) {
            return _c(
              "div",
              {
                key: itemIndex,
                staticClass:
                  "list-group-item list-group-item-action d-flex align-items-center flex-wrap",
                class: item.selected ? "selected" : ""
              },
              [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.selectItem(item, group)
                      }
                    }
                  },
                  [
                    _c("span", {
                      staticClass: "check",
                      class: item.selected ? "active" : ""
                    })
                  ]
                ),
                _c(
                  "a",
                  {
                    staticClass: "img-container",
                    style: { backgroundImage: "url(" + item.images[0] + ")" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.showImageModal(item.images)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "img-overlay" }),
                    _c("img", {
                      staticClass: "card-img",
                      staticStyle: { visibility: "hidden" },
                      attrs: { src: item.images[0], alt: item.name }
                    })
                  ]
                ),
                _c(
                  "a",
                  {
                    staticClass: "description flex-grow-1",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.selectItem(item, group)
                      }
                    }
                  },
                  [_c("span", [_vm._v(_vm._s(item.name))])]
                ),
                _c("span", { staticClass: "price ml-auto" }, [
                  _vm._v(_vm._s(_vm._f("dutchCurrency")(item.price)))
                ])
              ]
            )
          })
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }