<template>
  <div class="row">
    <loader v-if="isLoading"></loader>
    <div class="col col-lg-8 offset-lg-2"
         v-if="nouuid">
      <h1>Klik opnieuw op de link in de email</h1>
      <p>
        We kunnen uw account niet vinden, klik op de link in de ontvangen email om in te loggen/registreren.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import loader from "@/components/loader.vue";
export default {
  name: "init",
  data() {
    return {
      nouuid: false
    };
  },
  methods: {
    ...mapMutations({
      resetProject: 'project/resetProject',
    })
  },
  mounted() {
    if (!this.$route.query.uuid) {
      this.nouuid = true;
      this.resetProject();
    } else {
      this.$store.dispatch("customer/logout").finally(() => {
        this.$store
          .dispatch("customer/init", this.$route.query.uuid)
          .then(response => {

            switch (response.customer.status) {
              case "active":
                this.$router.push({
                  name: "authentication",
                  params: { uuid: this.customer.uuid }
                });
                break;
              case "invited":
                this.$router.push({
                  name: "registration",
                  params: { uuid: this.customer.uuid }
                });
                break;
              case "archived":
                this.$router.push({
                  name: "error",
                  params: { errorText: this.customer.status }
                });
                break;
            }
          })
          .catch(err => {
            this.$router.push({
              name: "error",
              params: { errorText: err.response.data.result }
            });
          });
      })
    }
  },
  components: {
    loader
  },
  computed: {
    ...mapGetters({
      isLoading: "customer/isLoading",
      customer: "customer/getCustomer"
    })
  }
};
</script>
