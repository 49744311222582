<template>
  <div
    class="modal fade"
    id="modalImage"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modal-image"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Sluiten"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div id="mediaCarousel" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner mb-3">
            <div
              class="carousel-item"
              :class="index === 0 ? 'active' : ''"
              v-for="(img, index) in media"
              v-bind:key="index"
            >
              <img :src="img" class="card-img" :alt="index" />
            </div>
          </div>
          <a
            v-if="media.length > 1"
            class="carousel-control-prev"
            href="#mediaCarousel"
            role="button"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Vorige</span>
          </a>
          <a
            v-if="media.length > 1"
            class="carousel-control-next"
            href="#mediaCarousel"
            role="button"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Volgende</span>
          </a>
          <ol v-if="media.length > 1" class="carousel-indicators">
            <li
              v-for="(img, index) in media"
              v-bind:key="index"
              data-target="#mediaCarousel"
              :data-slide-to="index"
              :class="index === 0 ? 'active' : ''"
            >
              <img :src="img" class="card-thumb" :alt="index" />
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
export default {
  name: 'imageModal',
  props: {
    media: {
      type: Array,
      required: true,
    },
  },
  updated() {
    $('#mediaCarousel .carousel-item:first-child').addClass('active');
  },
};
</script>
