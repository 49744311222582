import api from "@/api";
import Vue from 'vue';

const getDefaultState = () => {
  return {
    isLoading: false,
    project: sessionStorage.getItem('project') ? JSON.parse(sessionStorage.getItem('project')) : {},
    room: sessionStorage.getItem('room') ? JSON.parse(sessionStorage.getItem('room')) : {},
    design: {},
    order: null
  };
};

export default {
  state: getDefaultState(),
  mutations: {
    setLoadingState(state, payload) {
      state.isLoading = payload;
    },
    setProject(state, payload) {
      state.project = payload;
      sessionStorage.setItem('project', JSON.stringify(payload));
    },
    setRoom(state, payload) {
      state.room = payload;
      sessionStorage.setItem('room', JSON.stringify(payload));
    },
    setDesign(state, payload) {
      state.design = payload;
    },
    setProjectRoomDesignOrder(state, payload) {
      const projectRoom = state.project.rooms.find(item => item.uuid === state.room.uuid);
      Vue.set(projectRoom, 'order', payload);
      sessionStorage.setItem('project', JSON.stringify(state.project));
    },
    resetProject(state) {
      sessionStorage.removeItem("project");
      sessionStorage.removeItem("room");
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    getProject({ commit }, uuid) {
      commit("setLoadingState", true);
      return api.project
        .getProject(uuid)
        .then(response => {
          if (response.data.result === "ok") {
            commit("setProject", response.data.data);
          }
        })
        .finally(() => {
          commit("setLoadingState", false);
        });
    },
    getRoom({ commit }, uuid) {
      commit("setLoadingState", true);
      return api.project
        .getRoom(uuid)
        .then(response => {
          if (response.data.result === "ok") {
            commit("setRoom", response.data.data);
          }
        })
        .finally(() => {
          commit("setLoadingState", false);
        });
    },
    getDesign({ commit }, uuid) {
      commit("setLoadingState", true);
      return api.project
        .getDesign(uuid)
        .then(response => {
          if (response.data.result === "ok") {
            //add selected key to optional products
            response.data.data.products.optional_groups.map(group => {
              group.products.map(product =>
                Object.assign(product, { selected: false })
              );
            });
            //add id to included group (for collapsing on id)
            response.data.data.products.included_groups.map(group => {
                Object.assign(group, { id: window.btoa(Math.random()).substring(0,12) })
            });
            commit("setDesign", response.data.data);
          }
        })
        .finally(() => {
          commit("setLoadingState", false);
        });
    },
  },
  getters: {
    isLoading: state => state.isLoading,
    getProject: state => state.project,
    getRoom: state => state.room,
    getDesign: state => state.design,
  }
};
