var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row", attrs: { id: "error" } }, [
    _c("div", { staticClass: "col col-lg-8 offset-lg-2" }, [
      _c("h1", [_vm._v("Pagina niet gevonden")]),
      _c("p", [
        _vm._v(
          "De pagina waar u naar op zoek bent bestaat niet meer, is mogelijk verplaatst of is tijdelijk niet beschikbaar."
        )
      ]),
      _c(
        "p",
        [
          _vm._v("Keer terug naar de "),
          _c("router-link", { attrs: { to: "/" } }, [_vm._v("homepagina »")]),
          _vm._v(".")
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }